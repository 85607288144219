import { inject, Injectable } from '@angular/core';
import { BaseDatabaseService } from '@core/services/database/base-database.service';
import { environment } from '@env';
import { isPlatform } from '@ionic/angular';
import {
  prepareINSERT,
  prepareUPDATE,
  prepareVALUES
} from '@core/services/database/models/transaction.interface';
import { v4 as uuid } from 'uuid';
import {
  ClientDB,
  SearchClientParams,
  testClientsData
} from '@core/services/database/models/сlient.interface';
import { LoggerService } from '@core/services/logger/logger';
import { ClientOperationsService } from '@core/services/database/client-operations.service';

@Injectable({
  providedIn: 'root',
})
export class ClientsServiceDB extends BaseDatabaseService {
  private loggerService = inject(LoggerService);
  private clientOperationsService = inject(ClientOperationsService);

  /**
   * Delete all Clients and setup test data
   * @returns
   */
  async clearAndAddTestData(): Promise<boolean> {
    const res = await this.deleteClients();
    if (res) {
      for (const testClient of testClientsData) {
        await this.createClient(testClient, true);
      }
    }
    return true;
  }

  /**
   * Get all Clients
   * @returns
   */
  async getClients(
    searchParams: SearchClientParams,
    offset: number = 10,
    limit: number
  ): Promise<ClientDB[]> {
    let order = `created DESC`;
    let query = 'SELECT * FROM clients';
    if (searchParams.type && searchParams.search) {
      switch (searchParams.type) {
        case 'qr_code':
          query += ` WHERE qr_code = "${searchParams.search}"`;
          break;
        case 'rfid_tag':
          query += ` WHERE rfid_tag = "${searchParams.search}"`;
      }
    }
    query += ` ORDER BY ${order}`;
    query += ` LIMIT ${offset}, ${limit}`;

    const values = await this.mDb.query(query);
    this.loggerService.info('Clients', 'getClients', `$sql: ${query}`);
    return values.values as ClientDB[];

    // return (await this.mDb.query(query)).values as Client[];
  }

  async getClientByCode(code: string): Promise<ClientDB | null> {
    const query = `SELECT * FROM clients WHERE qr_code = "${code}"`;

    if (!environment.production) {
      console.log('#SQLClientByCode', query);
    }

    let ret: any = await this.mDb.query(query);
    if (ret.values.length > 0) {
      return ret.values[0] as ClientDB;
    }
    return null;
  }

  async getClientByRFID(rfid_tag: string): Promise<ClientDB | null> {
    const query = `SELECT * FROM clients WHERE rfid_tag = "${rfid_tag}"`;

    if (!environment.production) {
      console.log('#SQLClientByRFID', query);
    }

    let ret: any = await this.mDb.query(query);
    if (ret.values.length > 0) {
      return ret.values[0] as ClientDB;
    }
    return null;
  }

  /**
   * Create Client
   * @returns
   */
  async createClient(
    client: ClientDB,
    logOperation?: boolean
  ): Promise<boolean> {
    if (!client.uuid) {
      client.uuid = uuid();
    }

    if (logOperation) {
      const timestamp = new Date().getTime();
      client.created = timestamp;
      client.updated = timestamp;
    }

    const { sql, values } = prepareINSERT('clients', client);

    if (!environment.production) {
      console.log('#SQLCreateClient', sql);
    }

    try {
      await this.mDb.query(sql, prepareVALUES(values));
      if (!isPlatform('hybrid')) {
        await this.sqliteService.sqliteConnection.saveToStore(
          this.databaseName
        );
      }

      this.loggerService.info(
        'Clients',
        'createClient',
        `$sql: ${sql} ${JSON.stringify(values)}`
      );

      if (!logOperation) {
        return true;
      }

      const createdClient = await this.getClientByUUID(client.uuid);

      if (createdClient) {
        await this.clientOperationsService.addOperation(
          createdClient,
          'create'
        );
      }

      return true;
    } catch (e: any) {
      this.loggerService.error('Clients', 'createClient', e);
      return false;
    }
  }

  async updateClient(
    uuid: ClientDB['uuid'],
    client: Partial<ClientDB>,
    logOperation?: boolean
  ): Promise<boolean> {
    if (!uuid) {
      return false;
    }
    client.updated = new Date().getTime();
    const { sql, values } = prepareUPDATE('clients', client);
    try {
      await this.mDb.query(`${sql} WHERE uuid=?`, [
        ...prepareVALUES(values),
        uuid
      ]);
      if (!isPlatform('hybrid')) {
        await this.sqliteService.sqliteConnection.saveToStore(
          this.databaseName
        );
      }

      this.loggerService.info(
        'Clients',
        'updateClient',
        `$sql: ${sql} WHERE id=? ${JSON.stringify(values)}`
      );

      if (!logOperation) {
        return true;
      }

      const updatedClient = await this.getClientByUUID(uuid);

      if (updatedClient) {
        await this.clientOperationsService.addOperation(
          updatedClient,
          'update'
        );
      }

      return true;
    } catch (e) {
      this.loggerService.error('Clients', 'updateClient', e);
      return false;
    }
  }

  /**
   * Get count Clients
   * @returns
   */
  async countClients() {
    let sqlcmd: string = 'SELECT COUNT(*) as count FROM clients';
    let ret: any = await this.mDb.query(sqlcmd);
    if (ret.values.length > 0) {
      return ret.values[0].count;
    }
    throw Error('count clients failed');
  }

  /**
   * Get Client by uuid
   * @returns
   */
  async getClientByUUID(uuid: string): Promise<ClientDB | null> {
    let sqlcmd: string = 'SELECT * FROM clients WHERE uuid = ? LIMIT 1';
    let values: Array<any> = [uuid];
    let ret: any = await this.mDb.query(sqlcmd, values);

    if (ret.values.length > 0) {
      return ret.values[0] as ClientDB;
    }
    return null;
  }

  /**
   * Delete Client by uuid
   * @returns
   */
  async deleteClientByUUID(
    uuid: string,
    logOperation?: boolean
  ): Promise<boolean> {
    try {
      const client = await this.getClientByUUID(uuid);

      if (!client) {
        return false;
      }

      client.updated = new Date().getTime();

      await this.mDb.query(`DELETE FROM clients WHERE uuid = ?`, [uuid]);

      if (!isPlatform('hybrid')) {
        await this.sqliteService.sqliteConnection.saveToStore(
          this.databaseName
        );
      }

      if (!logOperation) {
        return true;
      }

      await this.clientOperationsService.addOperation(client, 'delete');

      return true;
    } catch (e) {
      this.loggerService.error('Clients', 'deleteClient', e);
      return false;
    }
  }

  /**
   * Delete all Clients
   * @returns
   */
  async deleteClients(): Promise<boolean> {
    await this.mDb.query(`DELETE FROM clients`);
    if (!isPlatform('hybrid')) {
      await this.sqliteService.sqliteConnection.saveToStore(this.databaseName);
    }
    return true;
  }
}
