/**
 * inCust Corporate Off-line Site API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Client } from './client';
import { OdometerUnitType } from './odometerUnitType';
import { VolumeUnitType } from './volumeUnitType';

export interface Refuel {
  /**
   * Client created date and time (UTC)
   */
  created?: string | null;
  driver?: Client;
  /**
   * Fuel filled volume
   */
  filled_volume?: number | null;
  /**
   * Fuel filled volume decimal digits
   */
  filled_volume_decimal_digits?: number | null;
  filled_volume_unit?: VolumeUnitType;
  /**
   * Internal grade ID / Grade number
   */
  grade?: number | null;
  /**
   * Internal Grade Code
   */
  grade_code?: string | null;
  /**
   * Refuel ID
   */
  id?: number | null;
  /**
   * Internal nozzle ID / Nozzle number
   */
  nozzle?: number | null;
  /**
   * Odometer/engine hours value, up to two decimal digits (optional)
   */
  odometer?: number | null;
  odometer_unit?: OdometerUnitType;
  /**
   * Internal pump ID / Pump number
   */
  pump?: number | null;
  request_pump_authorize?: object;
  response_pump_authorize?: object;
  /**
   * Off-line siye Title
   */
  title?: string | null;
  /**
   * Fuel controller Transaction Error Message (optional)
   */
  transaction_error_message?: string | null;
  /**
   * Fuel controller Transaction ID
   */
  transaction_id?: number | null;
  transaction_information?: object;
  /**
   * Fuel controller Transaction State
   */
  transaction_state?: string | null;
  /**
   * Client last updated date and time (UTC)
   */
  updated?: string | null;
  /**
   * Refuel unique ID
   */
  uuid?: string | null;
  vehicle?: Client;
}
export namespace Refuel {}
