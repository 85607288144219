/**
 * inCust Corporate Off-line Site API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type TransactionPaymentType =
  | 'currency'
  | 'special-account'
  | 'corporate-special-account-access'
  | 'retail-special-account-access';

export const TransactionPaymentType = {
  Currency: 'currency' as TransactionPaymentType,
  SpecialAccount: 'special-account' as TransactionPaymentType,
  CorporateSpecialAccountAccess:
    'corporate-special-account-access' as TransactionPaymentType,
  RetailSpecialAccountAccess:
    'retail-special-account-access' as TransactionPaymentType
};
