import { inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppStore } from '@core/store/app.store';
import { CONFIG_APP } from '@config-app';
import { environment } from '@env';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  protected readonly appStore = inject(AppStore);

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const { sync, login, password } = this.appStore.state().syncSettings;
    const serverReq = req.url.includes(environment.production ? CONFIG_APP.API_URL : CONFIG_APP.TEST_API_URL);

    if (!sync || !login || !password || !serverReq) {
      return next.handle(req);
    }

    const basicAuthHeader = `Basic ${btoa(`${login}:${password}`)}`;

    const authReq = req.clone({
      setHeaders: {
        Authorization: basicAuthHeader
      }
    });

    return next.handle(authReq);
  }
}
