/**
 * inCust Corporate Off-line Site API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TransactionType } from './transactionType';
import { TransactionPaymentType } from './transactionPaymentType';
import { TransactionErrors } from './transactionErrors';

export interface Transaction {
  /**
   * Transaction amount
   */
  amount?: number | null;
  /**
   * Number of decimal digits in amount value
   */
  amount_decimal_digits?: number | null;
  /**
   * Transaction amount to pay
   */
  amount_to_pay?: number | null;
  /**
   * Bonus points added during transaction
   */
  bonuses_added?: number | null;
  /**
   * Number of decimal digits in bonuses value
   */
  bonuses_decimal_digits?: number | null;
  /**
   * Bonus points account balance as result of transaction
   */
  bonuses_on_account?: number | null;
  /**
   * Bonus points redeemed during transaction
   */
  bonuses_redeemed?: number | null;
  /**
   * Business Staff ID (optional)
   */
  business_staff_id?: string | null;
  /**
   * Transaction canceled
   */
  canceled?: boolean | null;
  /**
   * Comment
   */
  comment?: string;
  /**
   * Corporate customer email
   */
  corporate_customer_email?: string | null;
  /**
   * Corporate customer ID
   */
  corporate_customer_id?: string;
  /**
   * Corporate customer name
   */
  corporate_customer_name?: string | null;
  /**
   * Currency ISO code (if transaction payment \'currency\')
   */
  currency?: string | null;
  /**
   * Transaction discount amount
   */
  discount_amount?: number | null;
  /**
   * Errors list (optional)
   */
  errors?: Array<TransactionErrors>;
  /**
   * Transaction finalized
   */
  finalized?: boolean | null;
  /**
   * Transaction ID
   */
  id?: string | null;
  /**
   * Loyalty ID
   */
  loyalty_id?: string;
  /**
   * Online store order ID
   */
  online_store_order_id?: string | null;
  payment_type?: TransactionPaymentType;
  /**
   * Pos ID
   */
  pos_id?: string;
  /**
   * Transaction UTC processed date-time
   */
  processed?: string | null;
  /**
   * Transaction local (by POS time zone) processed date-time
   */
  processed_local?: string | null;
  /**
   * Services amount
   */
  services_amount?: number | null;
  /**
   * Shipping amount
   */
  shipping_amount?: number | null;
  /**
   * Special account balance as result of transactions (if transaction payment in \'special-account\', \'corporate-special-account-access\', \'retail-special-account-access\')
   */
  special_account_amount?: number | null;
  /**
   * Special account ID (if transaction payment in \'special-account\', \'corporate-special-account-access\', \'retail-special-account-access\')
   */
  special_account_id?: string | null;
  /**
   * Number of decimal digits in special account value
   */
  special_accounts_decimal_digits?: number | null;
  subtype?: TransactionType;
  /**
   * Sum of amount and shipping_amount and services_amount (transaction total amount)
   */
  summary_amount?: number | null;
  /**
   * Terminal ID
   */
  terminal_id?: string;
  /**
   * Referenced transaction ID
   */
  transaction_id?: string;
  type?: TransactionType;
  /**
   * User email
   */
  user_email?: string;
  /**
   * User external id
   */
  user_external_id?: string;
  /**
   * User ID
   */
  user_id?: string;
  /**
   * User name
   */
  user_name?: string;
  /**
   * User phone number
   */
  user_phone?: string;
}
export namespace Transaction {}
