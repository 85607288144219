/**
 * inCust Corporate Off-line Site API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TransactionErrors {
  /**
   * Error code
   */
  code?: string;
  /**
   * Error level
   */
  level?: TransactionErrors.LevelEnum;
  /**
   * Error message
   */
  message?: string;
  /**
   * Error type
   */
  type?: TransactionErrors.TypeEnum;
}
export namespace TransactionErrors {
  export type LevelEnum = 'warning' | 'error';
  export const LevelEnum = {
    Warning: 'warning' as LevelEnum,
    Error: 'error' as LevelEnum
  };
  export type TypeEnum =
    | 'authorized-payment-capture'
    | 'transaction-post-processing';
  export const TypeEnum = {
    AuthorizedPaymentCapture: 'authorized-payment-capture' as TypeEnum,
    TransactionPostProcessing: 'transaction-post-processing' as TypeEnum
  };
}
