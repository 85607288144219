export enum EVENT {
  // CONFIRM_LOGOUT, // Запрос на выход из аккаунта
  CONFIRM_LOGOUT_GUEST_MODE, // Запрос на выход из режима гостя
  CONFIRM_LOGOUT_AND_REDIRECT_TO_TRANSACTIONS, // Запрос на выход из режима гостя
  LOGOUT_GUEST_MODE, // Запрос на выход из режима гостя
  LANGUAGE_CHANGE,
  SYNC
}

export interface EventAppObservable<T = unknown> {
  topic: EVENT;
  data: T;
}
