import { enableProdMode } from '@angular/core';
import {
  bootstrapApplication,
  enableDebugTools
} from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { environment } from '@env';
import { registerLocaleData } from '@angular/common';
import localeAz from '@angular/common/locales/az';
import localeHy from '@angular/common/locales/hy';
import localePl from '@angular/common/locales/pl';
import localeRu from '@angular/common/locales/ru';
import localeSk from '@angular/common/locales/sk';
import localeUk from '@angular/common/locales/uk';
import { defineCustomElements as pwaElements } from '@ionic/pwa-elements/loader';
import { defineCustomElements as jeepSqlite } from 'jeep-sqlite/loader';
import { Capacitor } from '@capacitor/core';
import { appConfig } from '@app/app.config';

registerLocaleData(localeUk, 'uk');
registerLocaleData(localeRu, 'ru');
registerLocaleData(localePl, 'pl');
registerLocaleData(localeSk, 'sk');
registerLocaleData(localeAz, 'az');
registerLocaleData(localeHy, 'hy');

if (environment.production) {
  enableProdMode();
}

const platform = Capacitor.getPlatform();
if (platform === 'web') {
  pwaElements(window).then( () => {
    jeepSqlite(window);

    window.addEventListener('DOMContentLoaded', async () => {
      const jeepEl = document.createElement('jeep-sqlite');
      document.body.appendChild(jeepEl);
      jeepEl.autoSave = false;
    });
  });
}

bootstrapApplication(AppComponent, appConfig)
  .then((ApplicationRef) => {
    console.log(`Bootstrap success`);
    if (!environment.production) {
      // allows to run `ng.profiler.timeChangeDetection();`
      enableDebugTools(ApplicationRef.components[0]);
    }
  })
  .catch((err) => console.log(err));
