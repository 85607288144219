import { capSQLiteVersionUpgrade } from '@capacitor-community/sqlite/src/definitions';

export const dbVersionUpgrades: capSQLiteVersionUpgrade[] = [
  {
    toVersion: 1,
    statements: [
      //@formatter:off
      `
        CREATE TABLE IF NOT EXISTS clients (
          id INTEGER PRIMARY KEY NOT NULL,
          identifier TEXT NOT NULL,
          code NUMBER DEFAULT NULL,
          rfid_tag TEXT DEFAULT NULL,
          fueling_limit NUMBER NOT NULL,
          driver_vehicle TEXT NOT NULL,
          CONSTRAINT code_unique UNIQUE (code),
          CONSTRAINT rfid_tag_unique UNIQUE (rfid_tag)
        );
      `,
      `
       CREATE TABLE IF NOT EXISTS transactions (
          id INTEGER PRIMARY KEY AUTOINCREMENT,
          client TEXT DEFAULT NULL,
          odometer TEXT DEFAULT NULL,
          pump INTEGER DEFAULT NULL,
          nozzle INTEGER DEFAULT NULL,
          fuelGrade TEXT DEFAULT NULL,
          transactionId INTEGER DEFAULT NULL,
          transactionState TEXT DEFAULT NULL,
          transactionErrorMessage TEXT DEFAULT NULL,
          filledVolume REAL DEFAULT NULL,
          filledVolumeNoChangeCount INTEGER DEFAULT 0,
          requestPumpAuthorize TEXT DEFAULT NULL,
          responsePumpAuthorize TEXT DEFAULT NULL,
          transactionInformation TEXT DEFAULT NULL,
          isTracking INTEGER DEFAULT 1,
          isRequestPumpStop INTEGER DEFAULT 0,
          created INTEGER DEFAULT NULL,
          updated INTEGER DEFAULT NULL
        );
      `
    ]
  },
  {
    toVersion: 2,
    statements: [
      //@formatter:off
      `ALTER TABLE clients ADD COLUMN created INTEGER DEFAULT NULL;`,
      `ALTER TABLE clients ADD COLUMN updated INTEGER DEFAULT NULL;`,
      `ALTER TABLE transactions RENAME COLUMN client TO driver;`,
      `ALTER TABLE transactions ADD COLUMN vehicle TEXT DEFAULT NULL;`
    ]
  },
  {
    toVersion: 3,
    statements: [
      //@formatter:off
      `ALTER TABLE clients ADD COLUMN uuid TEXT DEFAULT NULL;`,
      `ALTER TABLE transactions ADD COLUMN siteName TEXT DEFAULT NULL;`
    ]
  },
  {
    toVersion: 4,
    statements: [
      //@formatter:off
      `ALTER TABLE transactions ADD COLUMN uuid TEXT DEFAULT NULL;`,
      `ALTER TABLE transactions ADD COLUMN sync_api INTEGER DEFAULT 0;`
    ]
  },
  {
    toVersion: 5,
    statements: [
      //@formatter:off
      `CREATE TABLE IF NOT EXISTS client_operations (
         id TEXT NOT NULL PRIMARY KEY,
         type TEXT NOT NULL,
         client TEXT NOT NULL,
         processed INTEGER NOT NULL
       );`
    ]
  },
  {
    toVersion: 6,
    statements: [
      //@formatter:off
      `ALTER TABLE clients RENAME TO clients_old;`,
      `CREATE TABLE IF NOT EXISTS clients (
        uuid TEXT UNIQUE DEFAULT NULL,
        name TEXT NOT NULL,
        qr_code TEXT DEFAULT NULL,
        rfid_tag TEXT DEFAULT NULL,
        offline_one_time_fueling_limit NUMBER NOT NULL,
        type TEXT NOT NULL,
        created INTEGER DEFAULT NULL,
        updated INTEGER DEFAULT NULL,
        CONSTRAINT qr_code_unique UNIQUE (qr_code),
        CONSTRAINT rfid_tag_unique UNIQUE (rfid_tag)
       );`,
      `INSERT INTO clients (uuid, name, qr_code, rfid_tag, offline_one_time_fueling_limit, type, created, updated)
        SELECT uuid, identifier, CAST(code AS TEXT), rfid_tag, fueling_limit, driver_vehicle, created, updated
        FROM clients_old;
      `,
      `DROP TABLE clients_old;`
    ]
  },
  {
    toVersion: 7,
    statements: [
      `ALTER TABLE transactions RENAME TO transactions_old;`,
      `CREATE TABLE IF NOT EXISTS transactions (
      id INTEGER PRIMARY KEY AUTOINCREMENT,
      odometer TEXT DEFAULT NULL,
      pump INTEGER DEFAULT NULL,
      nozzle INTEGER DEFAULT NULL,
      fuel_grade TEXT DEFAULT NULL,
      transaction_id INTEGER DEFAULT NULL,
      transaction_state TEXT DEFAULT NULL,
      transaction_error_message TEXT DEFAULT NULL,
      filled_volume REAL DEFAULT NULL,
      filled_volume_no_change_count INTEGER DEFAULT 0,
      request_pump_authorize TEXT DEFAULT NULL,
      response_pump_authorize TEXT DEFAULT NULL,
      transaction_information TEXT DEFAULT NULL,
      is_tracking INTEGER DEFAULT 1,
      is_request_pump_stop INTEGER DEFAULT 0,
      created INTEGER DEFAULT NULL,
      updated INTEGER DEFAULT NULL,
      site_name TEXT DEFAULT NULL,
      uuid TEXT DEFAULT NULL,
      sync_api INTEGER DEFAULT 0,
      driver TEXT DEFAULT NULL,
      vehicle TEXT DEFAULT NULL
    );`,
      `INSERT INTO transactions (
      id, odometer, pump, nozzle, fuel_grade, transaction_id, transaction_state,
      transaction_error_message, filled_volume, filled_volume_no_change_count,
      request_pump_authorize, response_pump_authorize, transaction_information,
      is_tracking, is_request_pump_stop, created, updated, site_name, uuid,
      sync_api, driver, vehicle
    )
    SELECT
      id,
      odometer,
      pump,
      nozzle,
      fuelGrade,
      transactionId,
      transactionState,
      transactionErrorMessage,
      filledVolume,
      filledVolumeNoChangeCount,
      requestPumpAuthorize,
      responsePumpAuthorize,
      transactionInformation,
      isTracking,
      isRequestPumpStop,
      created,
      updated,
      siteName,
      uuid,
      sync_api,
      CASE
        WHEN driver IS NOT NULL AND driver != ''
        THEN json_object(
          'name',  json_extract(driver, '$.identifier'),
          'qr_code', CAST(json_extract(driver, '$.code') AS TEXT),
          'rfid_tag', json_extract(driver, '$.rfid_tag'),
          'offline_one_time_fueling_limit', json_extract(driver, '$.fueling_limit'),
          'type',  json_extract(driver, '$.driver_vehicle'),
          'created', created,
          'updated', updated
        )
        ELSE NULL
      END AS driver,
      CASE
        WHEN vehicle IS NOT NULL AND vehicle != ''
        THEN json_object(
          'name',  json_extract(vehicle, '$.identifier'),
          'qr_code', CAST(json_extract(vehicle, '$.code') AS TEXT),
          'rfid_tag', json_extract(vehicle, '$.rfid_tag'),
          'offline_one_time_fueling_limit', json_extract(vehicle, '$.fueling_limit'),
          'type',  json_extract(vehicle, '$.driver_vehicle'),
          'created', created,
          'updated', updated
        )
        ELSE NULL
      END AS vehicle
    FROM transactions_old;`,
      `DROP TABLE transactions_old;`
    ]
  },
  {
    toVersion: 8,
    statements: [
      //@formatter:off
      `ALTER TABLE transactions ADD COLUMN filled_volume_unit TEXT DEFAULT NULL;`,
      `ALTER TABLE transactions ADD COLUMN filled_volume_decimal_digits INTEGER DEFAULT 0;`
    ]
  }
];
