/**
 * inCust Corporate Off-line Site API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Client {
  /**
   * Client created date and time (UTC)
   */
  created?: string | null;
  /**
   * Client Name
   */
  name?: string | null;
  /**
   * Client one time refuel amount
   */
  offline_one_time_fueling_limit?: number | null;
  /**
   * Client Qr Code / Tag
   */
  qr_code?: string | null;
  /**
   * Client RFID / Fleet Tag
   */
  rfid_tag?: string | null;
  /**
   * Client type
   */
  type?: Client.TypeEnum | null;
  /**
   * Client last updated date and time (UTC)
   */
  updated?: string | null;
  /**
   * Client unique ID
   */
  uuid?: string | null;
}
export namespace Client {
  export type TypeEnum = 'driver' | 'vehicle';
  export const TypeEnum = {
    Driver: 'driver' as TypeEnum,
    Vehicle: 'vehicle' as TypeEnum
  };
}
