import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { Refuel, TransactionsService } from '@core/api';
import { firstValueFrom } from 'rxjs';
import { TransactionsServiceDB } from '@core/services/database/transactions.service';
import {
  TransactionDB,
  TransactionDBToRefuel
} from '@core/services/database/models/transaction.interface';

export interface TransactionOperation {
  transaction?: TransactionDB;
  synced?: boolean;
  processed?: number;
  error?: {
    code: number;
    field: string;
    message: string;
  } | null;
}

@Injectable({
  providedIn: 'root'
})
export class SyncTransactionsService {
  private transactionsServiceDB = inject(TransactionsServiceDB);
  private apiTransactionsService = inject(TransactionsService);
  public limit = 20;
  public finished = signal(false);
  public totalCount = signal(0);
  public processCount = signal(0);
  public processCountWithError = signal(0);
  public syncProcess = signal(false);
  public syncOperations: WritableSignal<TransactionOperation[]> = signal<
    TransactionOperation[]
  >([]);

  constructor() {}

  async getTotalCount() {
    return await this.transactionsServiceDB.getTransactionsCount({
      status: 'finished',
      sync_api: 0
    });
  }

  clear() {
    this.finished.set(false);
    this.totalCount.set(0);
    this.processCount.set(0);
    this.processCountWithError.set(0);
    this.syncProcess.set(false);
    this.syncOperations.set([]);
  }

  async sync() {
    if (this.syncProcess()) {
      return;
    }

    this.clear();
    this.syncProcess.set(true);

    const count = await this.transactionsServiceDB.getTransactionsCount({
      status: 'finished',
      sync_api: 0
    });
    this.totalCount.set(count);

    const syncTransactions = async (page: number, limit: number) => {
      const transactions = await this.transactionsServiceDB.getTransactions({
        status: 'finished',
        sync_api: 0,
        page,
        limit
      });

      if (transactions.length === 0) {
        return;
      }

      for (const transaction of transactions) {
        const processed = new Date().getTime();

        try {
          const data: Refuel = new TransactionDBToRefuel(transaction);

          const syncedTransaction = await firstValueFrom(
            this.apiTransactionsService.incustControllersCorporateOfflineSiteApiTransactionMake(
              data
            )
          );

          if (syncedTransaction) {
            const updateTransaction: TransactionDB = {
              ...transaction,
              sync_api: 1
            };
            await this.transactionsServiceDB.updateTransaction(
              updateTransaction.transaction_id,
              updateTransaction.pump,
              updateTransaction
            );
            const operation: TransactionOperation = {
              transaction: transaction,
              synced: true,
              error: null,
              processed
            };
            this.syncOperations.update((values) => {
              return [...values, operation];
            });
          }
        } catch (e: any) {
          const operation: TransactionOperation = {
            transaction: transaction,
            synced: false,
            error: e.error || null,
            processed
          };
          this.processCountWithError.set(this.processCountWithError() + 1);
          this.syncOperations.update((values) => {
            return [...values, operation];
          });
        }
        this.processCount.set(this.processCount() + 1);
      }
      await syncTransactions(page + 1, limit);
    };

    await syncTransactions(1, this.limit);
    this.syncProcess.set(false);
    this.finished.set(true);
  }

  public getBlobErrorsLogAsJson() {
    if (this.processCountWithError() === 0) {
      return;
    }
    const errors = this.syncOperations().filter((operation) => operation.error);
    const content = JSON.stringify(errors, null, 2);
    return new Blob([content], { type: 'application/json' });
  }
}
