import { Injectable, computed, effect, signal, inject } from '@angular/core';
import { Router } from '@angular/router';
import { EVENT } from '@core/services/events-app/events-app.interface';
import { EventsInApp } from '@core/services/events-app/events-app.service';

@Injectable({
  providedIn: 'root'
})
export class ActionSyncService {
  private readonly eventsInApp = inject(EventsInApp);
  private readonly router = inject(Router);

  private excludedRoutes = signal<string[]>([]);
  private currentRoute = signal<string>('');
  private actionEnabled = computed(() => !this.isExcluded(this.currentRoute()));

  private timerId: any = null;

  constructor() {
    this.router.events.subscribe(() => {
      this.currentRoute.set(this.router.url);
    });

    effect(async () => {
      if (this.actionEnabled()) {
        this.scheduleHourlyAction();
      } else {
        this.clearScheduledAction();
      }
    });
  }

  setExcludedRoutes(routes: string[]): void {
    this.excludedRoutes.set(routes);
  }

  private isExcluded(route: string): boolean {
    return this.excludedRoutes().some((excludedRoute) => {
      const pattern = new RegExp(`^${excludedRoute}`);
      return pattern.test(route);
    });
  }

  private calculateTimeToNextHour(): number {
    const now = new Date();
    const nextHour = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours() + 1,
      0,
      0,
      0
    );
    return nextHour.getTime() - now.getTime();
  }

  private scheduleHourlyAction(): void {
    const timeToNextHour = this.calculateTimeToNextHour();
    console.log(`Action scheduled at ${timeToNextHour}`);

    this.timerId = setTimeout(async () => {
      await this.performAction();
      this.scheduleHourlyAction();
    }, timeToNextHour);
  }

  private clearScheduledAction(): void {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  }

  private async performAction() {
    await this.eventsInApp.publish(EVENT.SYNC);
    console.log(`Action performed at ${new Date().toLocaleTimeString()}`);
  }
}
