import { Component, inject, OnInit } from '@angular/core';
import { IonicModule, NavController, ToastController } from '@ionic/angular';
import { EventsInApp } from '@core/services/events-app/events-app.service';
import { EVENT } from '@core/services/events-app/events-app.interface';
import {
  SystemMessagesService,
  TypeSystemMessage
} from '@core/services/system-messages/system-messages';
import { LanguagesService } from '@core/services/languages/languages.service';
import { Router } from '@angular/router';
import { AppStore } from '@core/store/app.store';
import { ClientsServiceDB } from '@core/services/database/client.service';
import { ActionSyncService } from '@core/services/sync/action-sync.service';
import { SyncClientsService } from '@core/services/sync/sync-clients.service';
import { SyncTransactionsService } from '@core/services/sync/sync-transactions.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { ExportFileService } from '@core/services/export/export.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule]
})
export class AppComponent implements OnInit {
  private readonly eventsInApp = inject(EventsInApp);
  private readonly systemMessages = inject(SystemMessagesService);
  private readonly toastController = inject(ToastController);
  private readonly clientsServiceDB = inject(ClientsServiceDB);
  private readonly languages = inject(LanguagesService);
  private readonly actionSyncService = inject(ActionSyncService);
  private readonly syncClientsService = inject(SyncClientsService);
  private readonly syncTransactionsService = inject(SyncTransactionsService);
  private readonly navigationService = inject(NavigationService);
  private readonly exportService = inject(ExportFileService);
  private readonly navCtrl = inject(NavController);
  private readonly router = inject(Router);
  private readonly appStore = inject(AppStore);

  constructor() {
    this.#initializeApp();
  }

  #initializeApp() {
    this.eventsInApp
      .channelSubscribe(EVENT.CONFIRM_LOGOUT_GUEST_MODE)
      .subscribe(async () => {
        await this.#confirmExitGuestMode();
      });
    this.eventsInApp
      .channelSubscribe(EVENT.CONFIRM_LOGOUT_AND_REDIRECT_TO_TRANSACTIONS)
      .subscribe(async () => {
        await this.#confirmExitGuestMode('/transactions', false);
      });
    this.eventsInApp.channelSubscribe(EVENT.SYNC).subscribe(async () => {
      if (
        !this.appStore.getState().syncSettings.sync ||
        !this.appStore.getState().syncSettings.login ||
        !this.appStore.getState().syncSettings.password
      ) {
        return;
      }
      await this.syncClientsService.sync();
      await this.syncTransactionsService.sync();
      await this.downloadSyncErrorLogFile();
    });
    this.actionSyncService.setExcludedRoutes([
      '/transactions',
      '/clients',
      '/sync',
      '/database'
    ]);
    this.navigationService.init();
  }

  async #confirmExitGuestMode(navigateTo = 'settings', replaceUrl = true) {
    const LANG = this.languages.translate.instant([
      'CONFIRMATION',
      'CONFIRMATION_EXIT_GUEST_MODE_MESSAGE',
      'PASSWORD_PLACEHOLDER',
      'INVALID_PASSWORD',
      'CANCEL',
      'OK',
      'QUIT'
    ]);
    const alert = await this.systemMessages.showMessage(
      {
        header: LANG.CONFIRMATION,
        message: LANG.CONFIRMATION_EXIT_GUEST_MODE_MESSAGE,
        backdropDismiss: false,
        keyboardClose: false,
        inputs: [
          {
            type: 'password',
            name: 'unlock-password',
            placeholder: LANG.PASSWORD_PLACEHOLDER,
            attributes: {
              autocorrect: 'off',
              autocapitalize: 'off',
              spellcheck: 'false',
              inputmode: 'numeric',
              autocomplete: 'off',
              autofocus: false,
              id: 'alertInput'
            }
          }
        ],
        buttons: [
          {
            text: LANG.CANCEL,
            role: 'cancel',
            handler: () => {}
          },
          {
            text: LANG.QUIT,
            role: 'destructive',
            cssClass: 'submit-button',
            handler: async (value) => {
              if (!value['unlock-password']) {
                return;
              }
              if (
                Number(value['unlock-password']) ===
                Number(this.appStore.getState('appSettings')?.passwordGuestMode)
              ) {
                this.appStore.patchState({
                  isGuestMode: false
                });
                this.navCtrl.setDirection('back');
                return await this.router.navigate([navigateTo], {
                  replaceUrl: replaceUrl
                });
              } else {
                const toast = await this.toastController.create({
                  header: LANG.INVALID_PASSWORD,
                  message: '',
                  duration: 3000
                });
                await toast.present();
                return;
              }
            }
          }
        ]
      },
      TypeSystemMessage.ALERT
    );

    const submitButton = alert.querySelector('.submit-button');
    if (submitButton) {
      submitButton.setAttribute('disabled', 'true');
    }

    // Add input event listener
    const inputElement = document.getElementById(
      'alertInput'
    ) as HTMLInputElement;
    inputElement?.addEventListener('input', () => {
      if (submitButton) {
        if (inputElement.value.trim()) {
          submitButton.removeAttribute('disabled');
        } else {
          submitButton.setAttribute('disabled', 'true');
        }
      }
    });

    alert.onDidDismiss().then(() => {
      inputElement.removeEventListener('input', () => {});
    });
  }

  async ngOnInit() {
    if (this.appStore.getState('firstTimeLoad')) {
      await this.clientsServiceDB.clearAndAddTestData();
      this.appStore.patchState({
        firstTimeLoad: false
      });
    }
  }

  async downloadSyncErrorLogFile() {
    const clientsBlob = this.syncClientsService.getBlobErrorsLogAsJson();
    const transactionsBlob =
      this.syncTransactionsService.getBlobErrorsLogAsJson();
    const timestamp = Date.now();

    if (clientsBlob) {
      await this.exportService.saveSomeFile(
        clientsBlob,
        `prime_offline_sync_clients_errors_log_${timestamp}.json`
      );
    }

    if (transactionsBlob) {
      await this.exportService.saveSomeFile(
        transactionsBlob,
        `prime_offline_sync_transactions_errors_log_${timestamp}.json`
      );
    }
  }
}
