import * as XLSX from 'xlsx';
import { isPlatform } from '@ionic/core';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import * as saveAs from 'file-saver';
import { inject, Injectable } from '@angular/core';
import { SystemMessagesService } from '@core/services/system-messages/system-messages';
import { AlertController } from '@ionic/angular';

export const STORAGE_DIR_PATH = 'STORAGE_DIR_PATH';

export enum formatSaveFile {
  'XLSX' = 'XLSX',
  'XLS' = 'XLS',
  'CSV' = 'CSV'
}

@Injectable({
  providedIn: 'root'
})
export class ExportFileService {
  protected readonly systemMessages = inject(SystemMessagesService);
  protected readonly alertController = inject(AlertController);

  constructor() {}

  saveFile(
    exportData: { [p: string]: string | null }[],
    format: formatSaveFile,
    filesData?: {
      path: string;
      type: string;
    },
    filename?: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
        const reader = new FileReader();
        switch (format) {
          case formatSaveFile.CSV:
            const csv: string = XLSX.utils.sheet_to_csv(ws, {
              FS: ';'
            });
            const blob = new Blob(
              [
                new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
                csv
              ],
              { type: 'application/octet-stream;charset=utf-8' }
            );
            reader.readAsText(blob);
            reader.onloadend = async () => {
              const res = reader.result;

              if (isPlatform('capacitor') && filesData) {
                const path = `${filesData.path}/${filename}.csv`;
                console.log('filesData', {
                  path: path,
                  directory:
                    filesData.type !== 'external'
                      ? Directory.Documents
                      : undefined,
                  data: res as string,
                  encoding: Encoding.UTF8
                });
                Filesystem.writeFile({
                  path: path,
                  data: res as string,
                  encoding: Encoding.UTF8
                })
                  .then((writeResult) => {
                    console.log('writeResult', writeResult);
                    resolve(path);
                  })
                  .catch((err) => {
                    console.log('err', err);
                    reject(err);
                  });
                // console.log('writeResult', writeResult)
                // if (writeResult) {
                //
                // }
              } else {
                const path = `${filename}.csv`;
                saveAs(blob, path);
                resolve(path);
              }
              return;
            };
            break;
          case formatSaveFile.XLS:
          case formatSaveFile.XLSX:
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            const blobXLS = new Blob(
              [
                XLSX.write(wb, {
                  bookType: format === formatSaveFile.XLS ? 'xls' : 'xlsx',
                  type: 'array'
                })
              ],
              { type: 'application/octet-stream;charset=utf-8' }
            );

            reader.readAsDataURL(blobXLS);
            reader.onloadend = async () => {
              const base64data = reader.result;
              if (isPlatform('capacitor') && filesData) {
                const path = `${filesData.path}/${filename}.${format === formatSaveFile.XLS ? 'xls' : 'xlsx'}`;
                console.log('filesData', {
                  path: path,
                  data: base64data as string
                });
                await Filesystem.writeFile({
                  path: path,
                  data: base64data as string
                })
                  .then((writeResult) => {
                    console.log('writeResult', writeResult);
                    resolve(path);
                  })
                  .catch((err) => {
                    console.log('err', err);
                    reject(err);
                  });
                // if (writeResult) {
                //   resolve(path);
                // }
              } else {
                const path = `${filename}.${format === formatSaveFile.XLS ? 'xls' : 'xlsx'}`;
                saveAs(blobXLS, path);
                resolve(path);
              }
            };
            break;
        }
      } catch (e) {
        console.log('e', e);
        reject(e);
      }
    });
  }

  saveSomeFile(blob: Blob, filename: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(blob);
      reader.onloadend = async () => {
        const res = reader.result;

        if (isPlatform('capacitor')) {
          console.log('filesData', {
            path: filename,
            directory: Directory.Documents,
            data: res as string,
            encoding: Encoding.UTF8
          });
          Filesystem.writeFile({
            path: filename,
            directory: Directory.Documents,
            data: res as string,
            encoding: Encoding.UTF8
          })
            .then((writeResult) => {
              console.log('writeResult', writeResult);
              resolve('/');
            })
            .catch((err) => {
              console.log('err', err);
              reject(err);
            });
        } else {
          const path = filename;
          saveAs(blob, path);
          resolve(path);
        }
      };
    });
  }
}
