export enum FuellingType {
  driver = 'driver',
  vehicle = 'vehicle',
  both = 'both'
}

export enum FuellingUnit {
  Litre = 'Litre',
  Gallon = 'Gallon'
}

export enum TypeAuthorizationPTS {
  Basic = 'Basic',
  Digest = 'Digest'
}

export const DefaultTypeAuthorizationPTS = TypeAuthorizationPTS.Digest;

export interface PTSSettings {
  protocol: string;
  ip: string;
  // host: string;
  username: string;
  password: string;
  typeAuthorization?: TypeAuthorizationPTS;
}

// export interface PTSTransactionTracking {
//   transaction: number;
//   pump: number;
//  // nozzle: number;
//   createdAt: string;
// }

//export type PTSTransactionTracking = PTSResponseData.PumpAuthorizeData & { createdAt?: string; }

interface AppSettings {
  fuellingType: FuellingType;
  fuellingUnit: FuellingUnit;
  requestOdometer: boolean;
  skipPump: boolean;
  passwordGuestMode?: string;
  importByFile: boolean;
  importByLink: boolean;
  importByQrCode: boolean;
  siteName?: string;
}

interface SyncSettings {
  sync: boolean;
  login?: string | null;
  password?: string | null;
}

export interface AppState {
  PTSSettings: PTSSettings | null;
  appSettings: AppSettings | null;
  syncSettings: SyncSettings;
  isGuestMode: boolean;
  firstTimeLoad: boolean;
  // transactionTracking: PTSTransactionTracking[];
  // [param: string]: any;
}
