import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { isPlatform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class DevProxyInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      !isPlatform('hybrid') &&
      location.href.includes('https') &&
      !request.url.includes('https') &&
      request.url.includes('http') &&
      !request.url.includes(location.origin)
    ) {
      const devProxyRequest = request.clone({
        url: `https://proxydev.incust.com:4433/${request.url}`,
        setHeaders: {
          'X-Dev-Proxy': 'VrHqau3vh+tAj%b&'
        }
      });
      return next.handle(devProxyRequest);
    }

    return next.handle(request);
  }
}
