import { inject, Injectable } from '@angular/core';
import {
  Router,
  NavigationStart,
  Event as NavigationEvent
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { EVENT } from '@core/services/events-app/events-app.interface';
import { EventsInApp } from '@core/services/events-app/events-app.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private previousUrl: string | null = null;
  private currentUrl: string | null = null;
  private readonly eventsInApp = inject(EventsInApp);
  private readonly router = inject(Router);

  constructor() {}

  init() {
    this.router.events
      .pipe(
        filter((event: NavigationEvent) => event instanceof NavigationStart)
      )
      .subscribe((event: NavigationStart) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.checkPreviousRoute(['/transactions', '/clients'], async () => {
          await this.eventsInApp.publish(EVENT.SYNC);
        });
      });
  }

  checkPreviousRoute(routes: string[], callback: () => void): void {
    if (
      this.previousUrl &&
      routes.some((route) => this.previousUrl!.startsWith(route))
    ) {
      callback();
    }
  }
}
