import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { inject, Injectable } from '@angular/core';
import { SQLiteService } from './sqlite.service';
import { DbnameVersionService } from './dbname-version.service';
import { isPlatform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class BaseDatabaseService {
  protected sqliteService: SQLiteService = inject(SQLiteService);
  protected dbVerService: DbnameVersionService = inject(DbnameVersionService);
  public databaseName = '';
  protected loadToVersion = 1;
  protected exec?: () => Promise<void>;
  public mDb!: SQLiteDBConnection;

  async initializeDatabase(
    dbName: string,
    version: number,
    mDb: SQLiteDBConnection
  ) {
    this.databaseName = dbName;
    this.loadToVersion = version;

    if (this.databaseName) {
      this.mDb = mDb;

      this.dbVerService.set(this.databaseName, this.loadToVersion);
      if (this.exec) {
        await this.exec();
      }

      if (!isPlatform('hybrid')) {
        //if (this.sqliteService.platform === 'web') {
        await this.sqliteService.sqliteConnection.saveToStore(
          this.databaseName
        );
      }
    }
  }
}
