/**
 * inCust Corporate Off-line Site API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Client } from './client';

export interface ClientsOperation {
  client?: Client;
  /**
   * Update data error (readonly)
   */
  process_error?: string | null;
  /**
   * Update data status (readonly)
   */
  process_status?: ClientsOperation.ProcessStatusEnum | null;
  /**
   * Operation processed date and time (UTC)
   */
  processed?: string | null;
  /**
   * Client type
   */
  type?: ClientsOperation.TypeEnum | null;
}
export namespace ClientsOperation {
  export type ProcessStatusEnum = 'success' | 'fail';
  export const ProcessStatusEnum = {
    Success: 'success' as ProcessStatusEnum,
    Fail: 'fail' as ProcessStatusEnum
  };
  export type TypeEnum = 'create' | 'update' | 'delete';
  export const TypeEnum = {
    Create: 'create' as TypeEnum,
    Update: 'update' as TypeEnum,
    Delete: 'delete' as TypeEnum
  };
}
