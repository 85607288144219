import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { timeout, catchError } from 'rxjs/operators';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  private readonly TIMEOUT = 600000;

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      timeout(this.TIMEOUT), // Устанавливаем тайм-аут
      catchError((error) => {
        if (error instanceof TimeoutError) {
          console.error('Request timed out');
        }
        return throwError(() => error);
      })
    );
  }
}
