import { Inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { AppState, FuellingType, FuellingUnit } from '@core/store/app.state';
import { StorageService } from '@core/services/storage/storage.service';
import { WINDOW } from '@ng-web-apis/common';
import { tap } from 'rxjs/operators';

export const STORAGE_APP_STATE = '_STORAGE_APP_STATE_V1_';

export class AppStateInitial implements AppState {
  PTSSettings = null;
  syncSettings = {
    sync: false,
    login: null,
    password: null
  };
  appSettings = {
    fuellingType: FuellingType.driver,
    fuellingUnit: FuellingUnit.Litre,
    requestOdometer: false,
    skipPump: true,
    passwordGuestMode: '1111',
    importByFile: true,
    importByLink: false,
    importByQrCode: false,
    siteName: 'example.com'
  };
  isGuestMode = false;
  requestOdometer = false;
  firstTimeLoad = true;
  // transactionTracking = [];
}

@Injectable({
  providedIn: 'root'
})
export class AppStore extends ComponentStore<AppState> {
  private isInitialize = false;

  constructor(
    private storage: StorageService,
    @Inject(WINDOW) readonly windowRef: Window
  ) {
    super(new AppStateInitial());
  }

  async initialize(initWithApiToken?: string): Promise<AppState> {
    if (this.isInitialize) {
      return this.get();
    }

    const prevState = await this.storage.get<AppState>(STORAGE_APP_STATE);
    if (prevState) {
      this.patchState(prevState);
    }

    this.state$
      .pipe(
        // tap((state) => console.log('AppStore::state', state)),
        tap((state) => this.storage.set(STORAGE_APP_STATE, state))
      )
      .subscribe();

    this.isInitialize = true;
    return this.get();
  }

  getState(): AppState;
  getState<K extends keyof AppState>(prop: K): AppState[K];
  getState<K extends keyof AppState>(prop?: K): AppState[K] | AppState {
    return this.get((state) => (prop ? state[prop] : state));
  }
}
