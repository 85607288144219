import { Routes } from '@angular/router';
import { isGuestMode, isNoGuestMode } from '@core/guards/guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'settings',
    pathMatch: 'full'
  },
  {
    path: 'settings',
    loadComponent: () =>
      import('./pages/settings/settings.page').then((m) => m.SettingsPage),
    canMatch: [isNoGuestMode()]
  },
  {
    path: 'welcome',
    loadComponent: () =>
      import('./pages/welcome/welcome.page').then((m) => m.WelcomePage),
    canMatch: [isGuestMode()]
  },
  {
    path: 'fuelling',
    loadComponent: () =>
      import('./pages/fuelling/fuelling.page').then((m) => m.FuellingPage),
    canMatch: [isGuestMode()]
  },
  {
    path: 'clients',
    loadComponent: () =>
      import('./pages/clients/clients.page').then((m) => m.ClientsPage)
    // TODO canMatch: [isNoGuestMode()]
  },
  {
    path: 'clients/import',
    loadComponent: () =>
      import('@app/pages/clients/import/import.page').then((m) => m.ImportPage)
    // TODO canMatch: [isNoGuestMode()]
  },
  {
    path: 'clients/edit/:clientUUID',
    loadComponent: () =>
      import('./pages/clients/edit/edit.page').then((m) => m.ClientEditPage)
  },
  {
    path: 'clients/add',
    loadComponent: () =>
      import('./pages/clients/edit/edit.page').then((m) => m.ClientEditPage)
  },
  {
    path: 'database',
    loadComponent: () =>
      import('./pages/database/database.page').then((m) => m.DatabasePage)
  },
  {
    path: 'sync',
    loadComponent: () =>
      import('./pages/sync/sync.page').then((m) => m.SyncPage)
  },
  {
    path: 'transactions',
    loadComponent: () =>
      import('./pages/transactions/transactions.page').then(
        (m) => m.TransactionsPage
      )
    // TODO canMatch: [isNoGuestMode()]
  },
  {
    path: '**',
    redirectTo: 'settings'
  }
];
