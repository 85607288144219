import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  NgModule
} from '@angular/core';
import { provideRouter, RouteReuseStrategy } from '@angular/router';
import { routes } from './app.routes';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import {
  HttpClient,
  HttpClientModule,
  provideHttpClient
} from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from '@core/services/languages/languages.service';
import { IonicStorageModule } from '@ionic/storage-angular';
import { CONFIG_APP } from '@config-app';
import { Drivers } from '@ionic/storage';
import { CoreModule } from '@core/core.module';
import { DatabaseModule } from '@core/services/database/database.module';
import { ApiModule, Configuration } from '@core/api';
import { environment } from '@env';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    importProvidersFrom(
      ApiModule.forRoot(
        () => new Configuration({ basePath: environment.production ? CONFIG_APP.API_URL : CONFIG_APP.TEST_API_URL }),
      ),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
        }
      }),
      HttpClientModule,
      IonicModule.forRoot({
        innerHTMLTemplatesEnabled: true,
        mode: 'md'
      }),
      IonicStorageModule.forRoot({
        name: '__' + CONFIG_APP.PACKAGE_NAME.replace(/\./g, '_'),
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
      }),
      CoreModule,
      DatabaseModule
    ),
    provideRouter(routes)
  ]
};
