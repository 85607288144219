import { inject, Injectable } from '@angular/core';
import {
  prepareINSERT,
  prepareVALUES
} from '@core/services/database/models/transaction.interface';
import { BaseDatabaseService } from '@core/services/database/base-database.service';
import { LoggerService } from '@core/services/logger/logger';
import { isPlatform } from '@ionic/angular';
import { v4 as uuid } from 'uuid';
import { ClientDB } from '@core/services/database/models/сlient.interface';
import { ClientOperationDB } from '@core/services/database/models/client-operation.interface';
import { ClientsOperation } from '@core/api';

@Injectable({
  providedIn: 'root',
})
export class ClientOperationsService extends BaseDatabaseService {
  private loggerService = inject(LoggerService);

  async operationsCount(): Promise<number> {
    try {
      const result = await this.mDb.query(
        `SELECT COUNT(*) AS count FROM client_operations`
      );

      const count = result.values?.[0]?.count;

      return count ? Number(count) : 0;
    } catch (e) {
      this.loggerService.error('Count client_operation', 'operationsCount', e);
      return 0;
    }
  }

  async getOperations(
    offset: number = 0,
    limit: number = 20
  ): Promise<ClientOperationDB[]> {
    let order = `processed DESC`;
    let query = 'SELECT * FROM client_operations';
    query += ` ORDER BY ${order}`;
    query += ` LIMIT ${offset}, ${limit}`;

    const values = await this.mDb.query(query);
    this.loggerService.info('Operations', 'getOperations', `$sql: ${query}`);
    return values.values as ClientOperationDB[];
  }

  async addOperation(client: ClientDB, type: ClientsOperation.TypeEnum) {
    let operation: ClientOperationDB = {
      id: uuid(),
      client: JSON.stringify(client),
      processed: new Date().getTime(),
      type
    };
    console.log('addClientOperation', operation);
    const { sql, values } = prepareINSERT('client_operations', operation);
    try {
      console.log('addClientOperation', prepareVALUES(values));
      console.log(this.mDb);
      await this.mDb.query(sql, prepareVALUES(values));
      if (!isPlatform('hybrid')) {
        await this.sqliteService.sqliteConnection.saveToStore(
          this.databaseName
        );
      }
      this.loggerService.info(
        'Add client_operation',
        'addClientOperation',
        `$sql: ${JSON.stringify(sql)}; values: ${JSON.stringify(values)} `
      );
      return true;
    } catch (e) {
      this.loggerService.error('Add client_operation', 'addClientOperation', e);
      return false;
    }
  }

  async removeOperation(id: string): Promise<boolean> {
    try {
      const sql = `DELETE FROM client_operations WHERE id = ?`;

      await this.mDb.query(sql, [id]);

      if (!isPlatform('hybrid')) {
        await this.sqliteService.sqliteConnection.saveToStore(
          this.databaseName
        );
      }

      this.loggerService.info(
        'Remove client_operation',
        'removeOperation',
        `$sql: ${JSON.stringify(sql)}; values: ${JSON.stringify(id)} `
      );

      return true;
    } catch (e) {
      this.loggerService.error('Remove client_operation', 'removeOperation', e);
      return false;
    }
  }

  async removeOperations(ids: string[]): Promise<boolean> {
    try {
      if (!ids || ids.length === 0) {
        return true;
      }

      const placeholders = ids.map(() => '?').join(',');

      const sql = `DELETE FROM client_operations WHERE id IN (${placeholders})`;
      await this.mDb.query(sql, ids);

      if (!isPlatform('hybrid')) {
        await this.sqliteService.sqliteConnection.saveToStore(
          this.databaseName
        );
      }

      this.loggerService.info(
        'Remove client_operation',
        'removeOperations',
        `$sql: ${JSON.stringify(sql)}; values: ${JSON.stringify(ids)} `
      );

      return true;
    } catch (e) {
      this.loggerService.error(
        'Remove client_operation',
        'removeOperations',
        e
      );
      return false;
    }
  }

  async dropOperations(): Promise<boolean> {
    await this.mDb.query(`DELETE FROM client_operations`);
    if (!isPlatform('hybrid')) {
      await this.sqliteService.sqliteConnection.saveToStore(this.databaseName);
    }
    return true;
  }
}
