import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { Client, ClientsOperation, ClientsService } from '@core/api';
import { ClientOperationsService } from '@core/services/database/client-operations.service';
import { firstValueFrom } from 'rxjs';
import { ClientDB } from '@core/services/database/models/сlient.interface';
import { ClientsServiceDB } from '@core/services/database/client.service';
import { ClientOperationDB } from '@core/services/database/models/client-operation.interface';

@Injectable({
  providedIn: 'root'
})
export class SyncClientsService {
  private clientsServiceDB = inject(ClientsServiceDB);
  private apiClientsService = inject(ClientsService);
  private clientOperationsService = inject(ClientOperationsService);
  public step = 20;
  public finished = signal(false);
  public totalCount = signal(0);
  public processCount = signal(0);
  public processCountWithError = signal(0);
  public syncProcess = signal(false);
  public downloadedClients = signal<{
    state: boolean;
    loading: boolean;
    error: boolean;
    errorMessage: string;
    count: number;
  } | null>(null);
  public syncOperations: WritableSignal<ClientsOperation[]> = signal<
    ClientsOperation[]
  >([]);

  constructor() {}

  async getTotalCount() {
    return await this.clientOperationsService.operationsCount();
  }

  clear() {
    this.finished.set(false);
    this.totalCount.set(0);
    this.processCount.set(0);
    this.processCountWithError.set(0);
    this.syncProcess.set(false);
    this.syncOperations.set([]);
    this.downloadedClients.set(null);
  }

  async sync() {
    if (this.syncProcess()) {
      return;
    }

    this.clear();
    this.syncProcess.set(true);

    const operationsIds: string[] = [];

    const count = await this.clientOperationsService.operationsCount();
    this.totalCount.set(count);

    const syncOperations = async (offset: number, limit: number) => {
      const operationsToSend: {
        clientOperation: ClientsOperation;
        clientOperationDB: ClientOperationDB;
      }[] = [];

      const operations = await this.clientOperationsService.getOperations(
        offset,
        limit
      );

      if (operations.length === 0) {
        return;
      }

      try {
        this.processCount.set(
          offset + limit < this.totalCount()
            ? offset + limit
            : this.totalCount()
        );

        operations.forEach((res) => {
          operationsIds.push(res.id);

          const clientParsed: ClientDB = JSON.parse(res.client);

          const client: Client = {
            ...clientParsed,
            updated: clientParsed.updated
              ? new Date(clientParsed.updated).toISOString()
              : null,
            created: clientParsed.created
              ? new Date(clientParsed.created).toISOString()
              : null
          };

          const operation: ClientsOperation = {
            processed: new Date(res.processed).toISOString(),
            client: client,
            type: res.type
          };

          operationsToSend.push({
            clientOperation: operation,
            clientOperationDB: res
          });
        });
        const syncResponse = await firstValueFrom(
          this.apiClientsService.incustControllersCorporateOfflineSiteApiClientsOperations(
            operationsToSend.map((el) => el.clientOperation) || []
          )
        );

        if (!syncResponse) {
          return;
        }

        syncResponse.forEach((res) => {
          if (res.process_error) {
            this.processCountWithError.set(this.processCountWithError() + 1);
          }

          const value = res;
          this.syncOperations.update((values) => {
            return [...values, value];
          });
        });

        await syncOperations(offset + this.step, limit);
      } catch (e: any) {
        for (const operation of operationsToSend) {
          this.processCountWithError.set(this.processCountWithError() + 1);

          const index = operationsIds.indexOf(operation.clientOperationDB.id);
          index > -1 && operationsIds.splice(index, 1);
          const value = operation.clientOperation;
          value.process_status = 'fail';
          value.process_error = e.error.message || 'Internet connection failed';
          this.syncOperations.update((values) => {
            return [...values, value];
          });
        }
      }
    };

    await syncOperations(0, this.step);
    await this.clientOperationsService.removeOperations(operationsIds);

    const updateClients = async () => {
      this.downloadedClients.set({
        state: false,
        loading: true,
        error: false,
        errorMessage: '',
        count: 0
      });

      try {
        const res = await firstValueFrom(
          this.apiClientsService.incustControllersCorporateOfflineSiteApiClientsGet()
        );

        if (!res) {
          return;
        }

        await this.clientsServiceDB.deleteClients();

        for (const data of res) {
          if (data.name && (data.rfid_tag || data.qr_code)) {
            const client: ClientDB = {
              created: data.created
                ? new Date(data.created).getTime()
                : undefined,
              updated: data.updated
                ? new Date(data.updated).getTime()
                : undefined,
              name: data.name || '',
              offline_one_time_fueling_limit:
                data.offline_one_time_fueling_limit || 0,
              qr_code: data.qr_code || null,
              rfid_tag: data.rfid_tag || null,
              type: data.type!,
              uuid: data.uuid!
            };
            await this.clientsServiceDB.createClient(client, false);
          }
        }
        this.downloadedClients.set({
          state: true,
          loading: false,
          error: false,
          errorMessage: '',
          count: res.length
        });
      } catch (e: any) {
        this.processCountWithError.set(this.processCountWithError() + 1);
        this.downloadedClients.set({
          state: false,
          loading: false,
          error: true,
          errorMessage: e.error.message || 'Internet connection failed',
          count: 0
        });
      }
    };

    await updateClients();
    this.syncProcess.set(false);
    this.finished.set(true);
  }

  public getBlobErrorsLogAsJson() {
    if (this.processCountWithError() === 0) {
      return;
    }
    const errors: Array<
      | ClientsOperation
      | {
          operation: string;
          error: boolean;
          message: string;
        }
    > = this.syncOperations().filter(
      (operation) =>
        operation.process_error && operation.process_status === 'fail'
    );

    const downloadedClients = this.downloadedClients();

    if (downloadedClients?.error) {
      errors.push({
        operation: 'download clients',
        error: downloadedClients.error,
        message: downloadedClients.errorMessage
      });
    }

    const content = JSON.stringify(errors, null, 2);
    return new Blob([content], { type: 'application/json' });
  }
}
