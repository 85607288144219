import { PTS } from '@core/services/PTSClient/types/PTS';
import { ClientDB } from '@core/services/database/models/сlient.interface';
import { Client, OdometerUnitType, Refuel, VolumeUnitType } from '@core/api';

export interface TransactionDB {
  //
  id?: number;
  // 331e3ade-2575-4320-9942-b40cb45c57d0 = 36
  uuid?: string | null;
  // TEXT =  JSON
  driver: string | null;
  // TEXT =  JSON
  vehicle: string | null;
  // String|null: example "123456"
  odometer: string | null;
  // Number|null: example 2
  pump: number | null;
  // Number|null: example 9
  nozzle: number | null;
  /**
   * Название топлива
   */
  fuel_grade: string | null;
  // Number|null: example 82596
  transaction_id?: number | null;

  transaction_state?: string | null;
  // String|null: example "JSONPTS_ERROR_COULD_NOT_GET_PRICE_VALUE"
  transaction_error_message?: string | null;
  /**
   * Сколько залито по факту
   */
  filled_volume?: number | null;
  filled_volume_no_change_count?: number;
  /**
   * 0 | 1
   */
  is_tracking: number;
  /**
   * Наша система вызывали команду PumpStop
   */
  is_request_pump_stop: number;
  //  Number|null: example 1704721938638
  created?: number;
  // Number|null; example 1704721938638
  updated?: number;
  request_pump_authorize?: string | null;
  response_pump_authorize?: string | null;
  transaction_information?: string | null;
  filled_volume_unit?: string | null;
  filled_volume_decimal_digits?: number | null;

  /**
   * Field from app settings
   */
  site_name?: string | null;
  //
  sync_api?: number | null;
}

export type ExplodeTransactionDB = Omit<
  TransactionDB,
  'driver' | 'vehicle' | 'requestPumpAuthorize'
> & {
  driver: ClientDB | null;
  vehicle: ClientDB | null;
  requestPumpAuthorize: PTS.Request[PTS.Commands.PumpAuthorize]['Data'] | null;
  responsePumpAuthorize:
    | PTS.Response[PTS.Commands.PumpAuthorize]['Data']
    | {
        // TODO
      }
    | null;
  transactionInformation:
    | PTS.Response[PTS.Commands.PumpGetTransactionInformation]['Data']
    | null;
};

export const ExplodeTransactionDB = (
  transaction: TransactionDB
): ExplodeTransactionDB => {
  const ExplodeTransactionDB: ExplodeTransactionDB = {
    ...transaction,
    ...{
      vehicle: null,
      driver: null,
      requestPumpAuthorize: null,
      responsePumpAuthorize: null,
      transactionInformation: null
    }
  };
  if (transaction.vehicle) {
    try {
      ExplodeTransactionDB.vehicle = JSON.parse(transaction.vehicle || '');
    } catch (error) {
      ExplodeTransactionDB.vehicle = null;
    }
  }

  if (transaction.driver) {
    try {
      ExplodeTransactionDB.driver = JSON.parse(transaction.vehicle || '');
    } catch (error) {
      ExplodeTransactionDB.driver = null;
    }
  }

  if (transaction.request_pump_authorize) {
    try {
      ExplodeTransactionDB.requestPumpAuthorize = JSON.parse(
        transaction.request_pump_authorize || ''
      );
    } catch (error) {
      ExplodeTransactionDB.requestPumpAuthorize = null;
    }
  }

  if (transaction.response_pump_authorize) {
    try {
      ExplodeTransactionDB.responsePumpAuthorize = JSON.parse(
        transaction.response_pump_authorize || ''
      );
    } catch (error) {
      ExplodeTransactionDB.responsePumpAuthorize = null;
    }
  } else {
    transaction.response_pump_authorize = null;
  }

  if (transaction.transaction_information) {
    try {
      ExplodeTransactionDB.transactionInformation = JSON.parse(
        transaction.transaction_information || ''
      );
    } catch (error) {
      ExplodeTransactionDB.transactionInformation = null;
    }
  }

  return ExplodeTransactionDB;
};

export const prepareINSERT = (
  tableName: string,
  values: object
): {
  sql: string;
  values: object;
} => {
  const fields = Object.keys(values);

  return {
    sql: `INSERT INTO ${tableName} (${fields.join(', ')})
          VALUES (${fields.map(() => '?').join(', ')})`,
    values: values
  };
};
export const prepareUPDATE = (
  tableName: string,
  values: object
): {
  sql: string;
  values: object;
} => {
  const fields = Object.keys(values);

  return {
    sql: `UPDATE ${tableName}
          SET ${fields.map((f) => `${f}=?`).join(', ')} `,
    values: values
  };
};
export const prepareVALUES = (values: object) => {
  return Object.values(values).map((value) => {
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    }
    return value;
  });
};

export class TransactionDBToRefuel implements Refuel {
  driver?: Client;
  vehicle?: Client;
  created?: string | null;
  filled_volume?: number | null;
  filled_volume_decimal_digits?: number | null;
  filled_volume_unit?: VolumeUnitType;
  grade?: number | null;
  grade_code?: string | null;
  id?: number | null;
  nozzle?: number | null;
  odometer?: number | null;
  odometer_unit?: OdometerUnitType;
  pump?: number | null;
  request_pump_authorize?: object;
  response_pump_authorize?: object;
  title?: string | null;
  transaction_error_message?: string | null;
  transaction_id?: number | null;
  transaction_information?: object;
  transaction_state?: string | null;
  updated?: string | null;
  uuid?: string | null;

  constructor(data: TransactionDB) {
    const parsedDriver = data.driver ? JSON.parse(data.driver) : null;

    this.driver = parsedDriver
      ? {
          ...parsedDriver,
          updated: parsedDriver.updated
            ? new Date(parsedDriver.updated).toISOString()
            : null,
          created: parsedDriver.created
            ? new Date(parsedDriver.created).toISOString()
            : null
        }
      : null;

    const parsedVehicle = data.vehicle ? JSON.parse(data.vehicle) : null;

    this.vehicle = parsedVehicle
      ? {
          ...parsedVehicle,
          updated: parsedVehicle.updated
            ? new Date(parsedVehicle.updated).toISOString()
            : null,
          created: parsedVehicle.created
            ? new Date(parsedVehicle.created).toISOString()
            : null
        }
      : null;

    this.filled_volume = data.filled_volume || null;

    this.filled_volume_decimal_digits =
      data.filled_volume_decimal_digits || null;

    if (
      data.filled_volume_unit === VolumeUnitType.G ||
      data.filled_volume_unit === VolumeUnitType.L
    ) {
      this.filled_volume_unit = data.filled_volume_unit;
    }

    this.grade_code = data.fuel_grade || null;
    this.id = data.id || null;
    this.nozzle = data.nozzle || null;
    this.odometer = Number(data.odometer);
    this.pump = data.pump || null;

    this.request_pump_authorize = data.request_pump_authorize
      ? JSON.parse(data.request_pump_authorize)
      : null;
    this.response_pump_authorize = data.response_pump_authorize
      ? JSON.parse(data.response_pump_authorize)
      : null;
    this.transaction_information = data.transaction_information
      ? JSON.parse(data.transaction_information)
      : null;
    this.transaction_error_message = data.transaction_error_message
      ? data.transaction_error_message
      : null;
    this.transaction_id = data.transaction_id ? data.transaction_id : null;
    this.transaction_state = data.transaction_state
      ? data.transaction_state
      : null;
    this.transaction_state = data.transaction_state
      ? data.transaction_state
      : null;
    this.updated = data.updated ? new Date(data.updated).toISOString() : null;
    this.created = data.created ? new Date(data.created).toISOString() : null;
  }
}
