/**
 * inCust Corporate Off-line Site API
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type VolumeUnitType = 'L' | 'G';

export const VolumeUnitType = {
  L: 'L' as VolumeUnitType,
  G: 'G' as VolumeUnitType
};
